import { VEHICLE_LOADED, OWN_FACILITY_COUNT_LOADED, GUARANTEED_FACILITY_COUNT_LOADED, CUSTOMER_LOADED, FACILITY_CUSTOMER_LOADED, FACILITY_LOADED, INSURANCE_LOADED, PAYMENT_HISTORY_LOADED, PAYMENT_SCHEDULE_LOADED, NOTES_LOADED, ARREARS_BREAKDOWN_LOADED, PD_CHEQUES_LOADED, GAURANTORS_LOADED, CAPITAL_LEDGER_LOADED } from '../actions/config';
import _ from 'lodash';

export default function (state = null, action) {
    switch (action.type) {
        case CUSTOMER_LOADED:
            return action.payload;
        case FACILITY_LOADED:
            // for (let i = 0; i < action.payload.length; i++) {
            //     const { home_phone, address, gender, nic, customer_fullName, sms_number, customer_number } = state;
            //     action.payload[i].customer = { home_phone, address, gender, nic, customer_fullName, sms_number, customer_number };
            // }
            state = { ...state, facilityDetails: action.payload };
            return state;
        case OWN_FACILITY_COUNT_LOADED:
            return { ...state, own_contracts: action.payload };
        case GUARANTEED_FACILITY_COUNT_LOADED:
            return { ...state, guaranteed_contracts: action.payload };
        case NOTES_LOADED:
            const contract_1 = _.find(state.facilityDetails, { contract_number: action.contract_number });
            Object.assign(contract_1, { ...contract_1, notes_list: action.payload });
            return { ...state };
        case PAYMENT_SCHEDULE_LOADED:
            const contract_2 = _.find(state.facilityDetails, { contract_number: action.contract_number });
            Object.assign(contract_2, { ...contract_2, payment_schedule: action.payload });
            return { ...state };
        case ARREARS_BREAKDOWN_LOADED:
            for (let i = 0; i < action.payload.length; i++) {
                action.payload[i].id = i;
            }
            const contract_3 = _.find(state.facilityDetails, { contract_number: action.contract_number });
            Object.assign(contract_3, { ...contract_3, arrears_breakdown: action.payload });
            return { ...state };
        case PD_CHEQUES_LOADED:
            for (let i = 0; i < action.payload.length; i++) {
                action.payload[i].id = i;
            }
            const contract_4 = _.find(state.facilityDetails, { contract_number: action.contract_number });
            Object.assign(contract_4, { ...contract_4, pd_cheques_list: action.payload });
            return { ...state };
        case PAYMENT_HISTORY_LOADED:
            const { contract_number, customer_name, ...ph } = action.payload;
            const payment_history = [];

            for (const key in ph) {
                ph[key].forEach(element => {
                    payment_history.push({ ...element });
                });
            }

            const contract_5 = _.find(state.facilityDetails, { contract_number: action.contract_number });
            Object.assign(contract_5, { ...contract_5, payment_history });
            return { ...state };
        case GAURANTORS_LOADED:
            const contract_6 = _.find(state.facilityDetails, { contract_number: action.contract_number });
            Object.assign(contract_6, { ...contract_6, gaurantors: action.payload });
            return { ...state };
        case INSURANCE_LOADED:
            const contract_7 = _.find(state.facilityDetails, { contract_number: action.contract_number });
            Object.assign(contract_7, { ...contract_7, insurance_list: action.payload });
            return { ...state };
        case VEHICLE_LOADED:
            const contract_8 = _.find(state.facilityDetails, { contract_number: action.contract_number });
            Object.assign(contract_8, { ...contract_8, vehicle: action.payload });
            return { ...state };
        case FACILITY_CUSTOMER_LOADED:
            const contract_9 = _.find(state.facilityDetails, { contract_number: action.contract_number });
            Object.assign(contract_9, { ...contract_9, customer: action.payload });
            return { ...state };
        case CAPITAL_LEDGER_LOADED:
            for (let i = 0; i < action.payload.length; i++) {
                action.payload[i].id = i;
            }
            const contract_10 = _.find(state.facilityDetails, { contract_number: action.contract_number });
            Object.assign(contract_10, { ...contract_10, capital_ledger: action.payload });
            return { ...state };
        default:
            return state;
    }
}
