import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import { API_URL } from '../config/globals';
import store from '../index';
import history from '../history';


import { USER_LOGGED_IN, USER_LOGGED_OUT } from './config';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Content-Type'] = "application/json";

axios.interceptors.response.use(function (response) {
    if (response.data && response.data.status === "error") {
        toastr.warning(response.data.message, response.data.description)
        return { data: null };
    } else {
        return response;
    }
}, function (error) {
    if (typeof error.response === "undefined") {
        store.dispatch({
            type: USER_LOGGED_OUT,
            payload: null
        });
        history.push("/");
        return Promise.reject(error);
    } else {
        return Promise.reject(error);
    }
});

export const login = (auth) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/oauth/token',
            params: {
                grant_type: "client_credentials",
            },
            auth: auth
        }).then(function (response) {
            if (response.data) {
                axios({
                    method: 'get',
                    url: API_URL + '/AccessManager/Login',
                    params: {
                        access_token: response.data.access_token,
                        user: auth.username
                    }
                }).then(function (userData) {
                    dispatch({
                        type: USER_LOGGED_IN,
                        payload: { ...response.data, ...userData.data }
                    });
                }).catch(function (error) {
                    toastr.error('Error', error.message);
                });
            }
        }).catch(function (error) {
            toastr.error('Error', "Invalid Credentials");
        });
    }
}

export const logout = (next) => {
    return (dispatch, getState) => {
        dispatch({
            type: USER_LOGGED_OUT,
            payload: null
        });
        next();
    }
}