import { combineReducers } from "redux";
import { reducer as form } from 'redux-form';
import { reducer as toastr } from 'react-redux-toastr';

import user from "./auth";
import metadata from "./metadata";
import users from "./users";
import groups from "./groups";
import facility from "./facility";
import facilities from "./facilities";
import leadstatus from "./leadstatus";
import campaigns from "./campaigns";
import roles from "./roles";
import permissions from "./permissions";
import configurations from "./configurations";
import followups from "./followups";
import agent_configs from "./agent_configs";
import group_configs from "./group_configs";
import templates from "./templates";
import kpi_report from "./kpi_report";
import call_counts from "./call_counts";
import agent_performance from "./agent_performance";
import reports from "./reports";
import images from "./images";
import agent_activity_tmpl from "./agent_activity_tmpl";
import agents from "./agents";
import agent_activities from "./agent_activities";
import agent_activity_report from "./agent_activity_report";
import email_templates from "../modules/email-templates/reducer";
import sms_templates from "../modules/sms-templates/reducer";
import disposition_templates from "../modules/disposition-note-templates/reducer";
import followup_templates from "../modules/followup-note-templates/reducer";
import cbs_templates from "../modules/cbs-note-templates/reducer";
import queue_statistics from "./queue_statistics";
import agent_etiquettes from "./agent_etiquettes";
import agent_monitor_data from "./agent_monitor_data";
import agent_activity_logs from "./agent_activity_logs";
import agent_status_changes from "./agent_status_changes";
import agent_call_counts from "./agent_call_counts";
import agent_collections from "./agent_collections";
import my_collection from "./my_collection";
import resynced_contracts from "./resynced_contracts";
import next_customer from "./next_customer";
import call_logs from "./call_logs";
import field_layout from "../modules/field-layout/reducer";
import queues from "./queues";

const rootReducer = combineReducers({
    user,
    metadata,
    form,
    toastr,
    users,
    groups,
    facility,
    facilities,
    leadstatus,
    campaigns,
    roles,
    permissions,
    configurations,
    followups,
    agent_configs,
    group_configs,
    templates,
    kpi_report,
    call_counts,
    agent_performance,
    reports,
    images,
    agent_activity_tmpl,
    agents,
    agent_activities,
    agent_activity_report,
    email_templates,
    sms_templates,
    disposition_templates,
    cbs_templates,
    followup_templates,
    queue_statistics,
    agent_etiquettes,
    agent_monitor_data,
    agent_activity_logs,
    agent_status_changes,
    agent_call_counts,
    agent_collections,
    my_collection,
    resynced_contracts,
    next_customer,
    call_logs,
    field_layout,
    queues
});

export default rootReducer;
