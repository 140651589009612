import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import { API_URL } from '../config/globals';
import { REPORT_KPI_LOADED, CALL_LOGS_LOADED, AGENT_STATUS_CHANGE_REQUESTS_LOADED, AGENT_COLLECTIONS_LOADED, AGENT_CALL_COUNTS_LOADED, QUEUE_STATS_LOADED, AGENT_ETIQUETTE_REPORT_LOADED, AGENT_ACTIVITY_LOGS_LOADED, AGENT_PERFORMANCE_LOADED, REPORTS_LOADED, AGENT_ACTIVITY_TMPL_LOADED, AGENT_ACTIVITY_REPORT_LOADED, AGENT_ACTIVITIES_LOADED, AGENTS_LOADED, AGENT_MONITOR_LOADED, MY_COLLECTIONS_LOADED } from './config';
import { getNow } from '../utils/common';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Content-Type'] = "application/json";

export const loadKPIReport = (data) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/DialerCore/getKPIData',
            params: {
                access_token: getState().user.access_token,
            },
            data
        }).then(function (response) {
            dispatch({ type: REPORT_KPI_LOADED, payload: response.data.kpi_data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadQueueStatistics = (data) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/DialerCore/getCallQueueStatistics.htm',
            params: {
                access_token: getState().user.access_token,
            },
            data
        }).then(function (response) {
            dispatch({ type: QUEUE_STATS_LOADED, payload: { data: response.data, query: data } });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadAgentPerformance = (data) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/getAgentPerformaceData',
            params: {
                access_token: getState().user.access_token,
            },
            data
        }).then(function (response) {
            dispatch({ type: AGENT_PERFORMANCE_LOADED, payload: response.data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const createReport = (data, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/DialerCore/generateCampaignReportGL',
            params: {
                access_token: getState().user.access_token
            },
            data
        }).then(function (response) {
            if (response.data) {
                toastr.success('Success', "Report creation will process in background.");
                dispatch(loadReports());
                next();
            } else {
                toastr.error('Error', 'Failed to create the report');
            }
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadReports = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/GetReportList',
            params: {
                access_token: getState().user.access_token,
            }
        }).then(function (response) {
            dispatch({ type: REPORTS_LOADED, payload: response.data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const deleteReport = (id) => {
    console.log(id);
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/deleteReport',
            params: {
                access_token: getState().user.access_token,
                report_id: id
            }
        }).then(function (response) {
            dispatch(loadReports());
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadAgentActivityTemplates = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/getTemplateList.htm',
            params: {
                access_token: getState().user.access_token,
            }
        }).then(function (response) {
            dispatch({ type: AGENT_ACTIVITY_TMPL_LOADED, payload: response.data.TemplateData });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadAgents = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/getAgentsList.htm',
            params: {
                access_token: getState().user.access_token,
            }
        }).then(function (response) {
            dispatch({ type: AGENTS_LOADED, payload: response.data.AgentData });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadAgentActivities = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/getAgentStatusList',
            params: {
                access_token: getState().user.access_token,
            }
        }).then(function (response) {
            dispatch({ type: AGENT_ACTIVITIES_LOADED, payload: response.data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const deleteAgentActivityTemplates = (id) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/DeleteTemplate.htm',
            params: {
                access_token: getState().user.access_token,
                template_id: id
            }
        }).then(function (response) {
            toastr.success('Success', "Template deleted");
            dispatch(loadAgentActivityTemplates());
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadAgentActivityReport = (data) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/DialerCore/getAgentData.htm',
            params: {
                access_token: getState().user.access_token,
            },
            data
        }).then(function (response) {
            if (data.TemplateName) {
                dispatch(loadAgentActivityTemplates());
            }
            dispatch({ type: AGENT_ACTIVITY_REPORT_LOADED, payload: response.data.ReportData });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadAgentActivityReportByTemplate = (data) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/DialerCore/getAgentDataFromTemplate.htm',
            params: {
                access_token: getState().user.access_token,
            },
            data
        }).then(function (response) {
            dispatch({ type: AGENT_ACTIVITY_REPORT_LOADED, payload: response.data.ReportData });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}


export const loadAgentEtiquettesReport = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/getAgentEtiquetteList',
            params: {
                access_token: getState().user.access_token,
            }
        }).then(function (response) {
            dispatch({ type: AGENT_ETIQUETTE_REPORT_LOADED, payload: response.data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const updateEtiquettesReport = (data) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/DialerCore/updateEtiquetteList',
            params: {
                access_token: getState().user.access_token,
            },
            data: {
                ...data,
                "rated_by": `${getState().user.user_details.first_name} ${getState().user.user_details.last_name} on ${getNow()}`
            }
        }).then(function (response) {
            dispatch(loadAgentEtiquettesReport());
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const getAgentMonitorData = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/getAgentMonitorData',
            params: {
                access_token: getState().user.access_token,
            }
        }).then(function (response) {
            dispatch({ type: AGENT_MONITOR_LOADED, payload: response.data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const getAgentStatusRequestsList = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/getAgentStatusRequestsList',
            params: {
                access_token: getState().user.access_token,
            }
        }).then(function (response) {
            dispatch({ type: AGENT_STATUS_CHANGE_REQUESTS_LOADED, payload: response.data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}


export const approveStatusRequest = (data) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/approveStatusRequest',
            params: {
                access_token: getState().user.access_token,
                ...data
            },
        }).then(function (response) {
            dispatch(getAgentMonitorData());
            dispatch(getAgentStatusRequestsList());
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadAgentActivityLogReport = (data) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/DialerCore/getAgentActivityReport',
            params: {
                access_token: getState().user.access_token,
            },
            data
        }).then(function (response) {
            if (data.TemplateName) {
                dispatch(loadAgentActivityTemplates());
            }
            dispatch({ type: AGENT_ACTIVITY_LOGS_LOADED, payload: response.data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}


export const getAgentCallStatisticsReport = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/getAgentCallStatisticsReport',
            params: {
                access_token: getState().user.access_token,
            }
        }).then(function (response) {
           dispatch({ type: AGENT_CALL_COUNTS_LOADED, payload: response.data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}


export const getAgentCollections = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/getAgentCollections',
            params: {
                access_token: getState().user.access_token,
            }
        }).then(function (response) {
           dispatch({ type: AGENT_COLLECTIONS_LOADED, payload: response.data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const getAgentMonthlyCollection = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/getAgentMonthlyCollection',
            params: {
                access_token: getState().user.access_token,
                extension: getState().user.user_details.extension,
            }
        }).then(function (response) {
           dispatch({ type: MY_COLLECTIONS_LOADED, payload: response.data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadCallLogs = (data, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/DialerCore/getCallLog.htm',
            params: {
                access_token: getState().user.access_token,
            },
            data
        }).then(function (response) {
            if (data.TemplateName) {
                dispatch(loadAgentActivityTemplates());
            }
            dispatch({ type: CALL_LOGS_LOADED, payload: response.data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        }).finally(()=>{
            next();
        });
    }
}