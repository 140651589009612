import { MY_COLLECTIONS_LOADED } from '../actions/config';

export default function (state = null, action) {
    switch (action.type) {
        case MY_COLLECTIONS_LOADED:
            return action.payload;
        default:
            return state;
    }
}
