import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import { API_URL } from '../config/globals';
import { EXTENTION_LOADED, KPIPARAMS_LOADED, QUEUES_LOADED } from './config';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Content-Type'] = "application/json";

export const listExtensions = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/AccessManager/extenstionsList',
            params: {
                access_token: getState().user.access_token,
            }
        }).then(function (response) {
            dispatch({ type: EXTENTION_LOADED, payload: response.data.extensions });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const listKPIParameters = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/getKPIParameters',
            params: {
                access_token: getState().user.access_token,
            }
        }).then(function (response) {
            dispatch({ type: KPIPARAMS_LOADED, payload: response.data.KPI_List });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const deleteExtention = (props) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/AccessManager/deleteExtension',
            params: {
                access_token: getState().user.access_token,
                id: props
            }
        }).then(function (response) {
            if (response.data) {
                toastr.success('Success', response.data.message);
                //dispatch({ type: EXTENTION_DELETED, payload: response.data });
                dispatch(listExtensions());
            } else {
                toastr.error('Error', 'Failed to delete extention');
            }

        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const createExtention = (props) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/AccessManager/addExtension',
            params: {
                access_token: getState().user.access_token,
                extension: props.extension
            }
        }).then(function (response) {
            if (response.data) {
                toastr.success('Success', response.data.message);
                dispatch(listExtensions());
            }
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });

    }
}


export const setTarget = (data, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/AccessManager/updateExtensionTargets',
            params: {
                access_token: getState().user.access_token
            },
            data
        }).then(function (response) {
            if (response.data) {
                toastr.success('Success', response.data.message);
                dispatch(listExtensions());
                next();
            }
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });

    }
}

export const listQueues = (next) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/AccessManager/queueList',
            params: {
                access_token: getState().user.access_token,
            }
        }).then(function (response) {
            dispatch({ type: QUEUES_LOADED, payload: response.data });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        }).finally(() => {
            if (next) {
                next();
            }
        });
    }
}