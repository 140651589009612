//auth
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_CONFIG_CHANGED = "USER_CONFIG_CHANGED";
export const DELETE_GROUP = "DELETE_GROUP";
export const ROLES_LOADED = "ROLES_LOADED";
export const DELETE_ROLE = "DELETE_ROLE";
export const USER_IMAGE_LOADED = "USER_IMAGE_LOADED";
export const PERMISSION_LOADED = "PERMISSION_LOADED";
export const USER_CONFIG_LOADED = "USER_CONFIG_LOADED";
export const GROUP_CONFIG_LOADED = "GROUP_CONFIG_LOADED";

//metadata
export const BRANCHES_LOADED = "BRANCHES_LOADED";
export const PERIOD_DETAILS_LOADED = "PERIOD_DETAILS_LOADED";
export const PRODUCT_DETAILS_LOADED = "PRODUCT_DETAILS_LOADED";
export const STATUS_DETAILS_LOADED = "STATUS_DETAILS_LOADED";
export const LEAD_STATUS_LOADED = "LEAD_STATUS_LOADED";

//users
export const USERS_LOADED = "USERS_LOADED";
export const USER_DELETED = "USER_DELETED";
export const PROFILE_UPDATED = "PROFILE_UPDATED";
export const MY_COLLECTIONS_LOADED = "MY_COLLECTIONS_LOADED";

//groups
export const GROUPS_LOADED = "GROUPS_LOADED";
export const GROUP_DELETED = "GROUP_DELETED";
export const GROUP_CONFIG_CHANGED = "GROUP_CONFIG_CHANGED";

//facilities
export const CUSTOMER_LOADED = "CUSTOMER_LOADED";
export const NEXT_CUSTOMER_LOADED = "NEXT_CUSTOMER_LOADED";
export const FACILITY_LOADED = "FACILITY_LOADED";
export const FACILITIES_LOADED = "FACILITIES_LOADED";
export const PAYMENT_SCHEDULE_LOADED = "PAYMENT_SCHEDULE_LOADED";
export const NOTES_LOADED = "NOTES_LOADED";
export const ARREARS_BREAKDOWN_LOADED = "ARREARS_BREAKDOWN_LOADED";
export const PD_CHEQUES_LOADED = "PD_CHEQUES_LOADED";
export const PAYMENT_HISTORY_LOADED = "PAYMENT_HISTORY_LOADED";
export const GAURANTORS_LOADED = "GAURANTORS_LOADED";
export const INSURANCE_LOADED = "INSURANCE_LOADED";
export const VEHICLE_LOADED = "VEHICLE_LOADED";
export const FACILITY_CUSTOMER_LOADED = "FACILITY_CUSTOMER_LOADED";
export const GUARANTEED_FACILITY_COUNT_LOADED = "GUARANTEED_FACILITY_COUNT_LOADED";
export const OWN_FACILITY_COUNT_LOADED = "OWN_FACILITY_COUNT_LOADED";
export const CAPITAL_LEDGER_LOADED = "CAPITAL_LEDGER_LOADED";

//followups
export const FOLLOWUPS_SAVED = "FOLLOWUPS_SAVED";
export const FOLLOWUPS_LOADED = "FOLLOWUPS_LOADED";
export const FOLLOWUP_METADATA_LOADED = "FOLLOWUP_METADATA_LOADED";

//campaigns
export const CAMPAIGNS_LOADED = "CAMPAIGNS_LOADED";
export const TEMPLATES_LOADED = "TEMPLATES_LOADED";
export const CAMPAIGNS_RESYNCED = "CAMPAIGNS_RESYNCED";

//extentions
export const EXTENTION_LOADED = "EXTENTION_LOADED";
export const EXTENTION_DELETED = "EXTENTION_DELETED";
export const KPIPARAMS_LOADED = "KPIPARAMS_LOADED";

//reports
export const REPORT_KPI_LOADED = "REPORT_KPI_LOADED";
export const CALL_COUNTS_LOADED = "CALL_COUNTS_LOADED";
export const AGENT_PERFORMANCE_LOADED = "AGENT_PERFORMANCE_LOADED";
export const REPORTS_LOADED = "REPORTS_LOADED";
export const AGENT_ACTIVITY_TMPL_LOADED = "AGENT_ACTIVITY_TMPL_LOADED";
export const AGENT_ACTIVITIES_LOADED = "AGENT_ACTIVITIES_LOADED";
export const AGENT_ACTIVITIES_SELECTED = "AGENT_ACTIVITIES_SELECTED";
export const AGENTS_LOADED = "AGENTS_LOADED";
export const AGENT_ACTIVITY_REPORT_LOADED = "AGENT_ACTIVITY_REPORT_LOADED";
export const QUEUE_STATS_LOADED = "QUEUE_STATS_LOADED";
export const AGENT_ETIQUETTE_REPORT_LOADED = "AGENT_ETIQUETTE_REPORT_LOADED";
export const AGENT_MONITOR_LOADED = "AGENT_MONITOR_LOADED";
export const AGENT_ACTIVITY_LOGS_LOADED = "AGENT_ACTIVITY_LOGS_LOADED";
export const AGENT_STATUS_CHANGE_REQUESTS_LOADED = "AGENT_STATUS_CHANGE_REQUESTS_LOADED";
export const AGENT_CALL_COUNTS_LOADED = "AGENT_CALL_COUNTS_LOADED";
export const AGENT_COLLECTIONS_LOADED = "AGENT_COLLECTIONS_LOADED";
export const CALL_LOGS_LOADED = "CALL_LOGS_LOADED"
export const QUEUES_LOADED = "QUEUES_LOADED";

export const searchTypes = { LEAD_ID: "Lead ID", PHONE_NUMBER: "Phone Number" };




