import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Nav } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { getAgentStatusRequestsList, getAgentMonthlyCollection } from '../actions/reports';
import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import { checkAccessiability } from '../config/util';
import { loadFollowUps } from '../actions/facilities';
import UserMenu from './menus/UserMenu';
import { formatCurrency } from '../config/util';
import text from '../theme/texts.json';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asideContent: "",
      showBreakPanel: false
    }
  }

  componentDidMount() {
    this.props.loadFollowUps();
    this.props.getAgentMonthlyCollection();
    this.setState({ showBreakPanel: checkAccessiability('User Management', 'WRITE') });

    if (checkAccessiability('Reports', 'READ')) {
      this.props.getAgentStatusRequestsList();
    }
  }

  toggleAsideMenu(content) {
    const asideOpened = document.body.classList.contains('aside-menu-lg-show');
    this.props.onAsideMenuClicked(content);
    this.setState({ asideContent: content });

    if (this.state.asideContent === content && asideOpened) {
      document.body.classList.remove('aside-menu-lg-show');
    } else {
      document.body.classList.add('aside-menu-lg-show');
    }
  }

  activitySidebarTogglerClick(e) {
    this.toggleAsideMenu("activity");
  }

  followupSidebarTogglerClick(e) {
    this.toggleAsideMenu("followup");
  }

  render() {
    const { followups, agent_status_changes, my_collection } = this.props;
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <AppNavbarBrand><b>{text.title}</b></AppNavbarBrand>
        <Nav className="ml-auto px-3" navbar>
          {my_collection && my_collection.collection > 0 ? <span className="mx-3 rounded bg-light px-3 py-1"> <i className="fa fa-trophy" ></i> You have collected <b>Rs. {formatCurrency(my_collection.collection)}</b> from {my_collection.count} leads this month</span> : ""}
          {this.state.showBreakPanel && <Button onClick={this.activitySidebarTogglerClick.bind(this)} className="d-md-down-none remiderButton" ><i className="fa fa-hourglass-half" ></i><span className="count">{agent_status_changes.length}</span></Button>}
          <Button onClick={this.followupSidebarTogglerClick.bind(this)} className="d-md-down-none remiderButton" ><i className="fa fa-bell" ></i><span className="count">{followups.length}</span></Button>
          <UserMenu />
        </Nav>
      </React.Fragment>
    );
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;


function mapStateToProps({ followups, agent_status_changes, my_collection }) {
  return {
    followups,
    agent_status_changes,
    my_collection
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadFollowUps,
    getAgentStatusRequestsList,
    getAgentMonthlyCollection
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
