import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { ListGroup, ListGroupItem } from 'reactstrap';
import _ from 'lodash';
import { withRouter } from 'react-router';

import Modal from '../../../components/Modal';
import AddDispositionNote from '../../../modules/disposition-note-templates/views/send';
import { loadFollowUps, deleteFollowUps } from '../../../actions/facilities';
import { NOTIFICATION_REFRESH_INTERVAL } from '../../../config/globals';
import { searchTypes } from '../../../actions/config';

class DefaultAside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDispositionNote: false
    }
  }
  componentWillMount() {
    this.props.loadFollowUps();
    setInterval(() => {
      this.props.loadFollowUps();
    }, NOTIFICATION_REFRESH_INTERVAL * 1000);
  }

  handleEditRow(row) {
    //this.props.history.push("/contracts/dial", { nic: row.nic, clickedReminder: true })
    this.props.history.push({
      pathname: "/leads/dial",
      search: `?search=${row.costomer_id}&searchBy=${searchTypes.LEAD_ID}&clickedReminder=true`
    });
  }

  handleDeleteRow(row) {
    if (window.confirm("Are you sure, you want to delete this follow up?")) {
      const self = this;
      this.setState({
        showDispositionNote: true, customer_id: row.costomer_id, dispositionCallBack: () => {
          self.setState({ showDispositionNote: false });
          self.props.deleteFollowUps(row.costomer_id);
        }
      });
    }
  }

  render() {
    const { followups } = this.props;
    return (
      <div>
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item active">Follow Ups</li>
        </ol>
        <ListGroup className="list-group-accent" style={{ overflow: "auto", height: "calc(100vh - 100px)" }}>
          {
            _.orderBy(followups, ['due_on'], ['asc']).map((f) => {
              return <ListGroupItem key={f.costomer_id} className={"list-group-item-divider " + f.color_code}>
                <div>
                  <strong>{f.costomer_id}</strong>
                  <div className="float-right">
                    <div onClick={() => this.handleEditRow(f)} className="call-button mr-1">
                      <i className="fa fa-phone" ></i>
                    </div>
                    <div onClick={() => this.handleDeleteRow(f)} className="call-button danger">
                      <i className="fa fa-trash" ></i>
                    </div>
                  </div>
                </div>
                <div>
                  {f.followup_note}
                </div>
                <small className="text-muted mr-3">
                  <i className="fa fa-clock-o" ></i>&nbsp; {f.due_on}
                </small>
              </ListGroupItem>
                ;
            })
          }
        </ListGroup>
        <Modal title="Add Disposition Note" toggle={() => { this.state.dispositionCallBack() }} isOpen={this.state.showDispositionNote}>
          <AddDispositionNote onSuccess={() => { this.state.dispositionCallBack() }} customer_id={this.state.customer_id} />
        </Modal>
      </div >
    );
  }
}

function mapStateToProps({ followups }) {
  return {
    followups
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadFollowUps,
    deleteFollowUps
  }, dispatch);
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(DefaultAside);
