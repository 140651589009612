import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import logo from '../theme/logo-dark.png';

import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarHeader,
  AppSidebarNav,
  AppAside
} from '@coreui/react';

import Footer from './Footer';
import Header from './Header';
import Reminders from './views/FollowUps/reminders';
import ActivityNotifications from './views/Reports/ActivityMonitor/notification';
import routes from '../config/routes';
import navigation from '../config/navigation';
import { checkAccessiability } from '../config/util';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAside: "followup",
      showActivityMonitor: false
    }
  }

  componentDidMount() {
    if (checkAccessiability('Reports', 'READ')) {
      this.setState({ showActivityMonitor: true });
    }
  }

  render() {
    const menu = navigation.getItems();
    console.log(menu);

    return (
      <div className="app">
        <AppHeader fixed>
          <Header onAsideMenuClicked={(id) => { this.setState({ selectedAside: id }) }} />
        </AppHeader>
        <div className="app-body">
          <AppSidebar className="bg-primary" fixed display="lg">
            <AppSidebarHeader />
            <div className="p-5">
              <div className="bg-white rounded p-3">
                <img className="w-100" src={logo} alt={"MI"} />
              </div>
            </div>
            <AppSidebarNav style={{ paddingBottom: 40 }} navConfig={menu} {...this.props} />
          </AppSidebar>
          <main className="main">
            <Container fluid className="px-0">
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                    <route.component {...props} />
                  )} />)
                    : (null);
                },
                )}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
          <AppAside fixed>
            {this.state.selectedAside === "activity" && this.state.showActivityMonitor ? <ActivityNotifications /> : <Reminders />}
          </AppAside>
        </div>
        <AppFooter>
          <Footer />
        </AppFooter>
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return {
    user
  };
}

export default connect(mapStateToProps, null)(Home);
