import React, { Component } from 'react';
import { CardBody, Row, Col, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { createUser, updateUser } from '../../../actions/users';
import { listRoles } from '../../../actions/roles';
import { listGroups } from '../../../actions/groups';
import { listExtensions } from '../../../actions/configurations';

const roleOption = [];
const extensionOption = [];
const groupOption = [];

class CreateUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login_username: "",
            login_password: "",
            login_role_id: "",
            selected_role: "",
            first_name: "",
            last_name: "",
            email: "",
            phone_office: "",
            extension_id: "",
            selected_extension: "",
            work_group_id: "",
            selected_group: "",
            conf_password: "",
            error_name: "",
            error_username: "",
            error_email: "",
            error_password: "",
            error_conf_password: "",
            error_role: "",
            login_id: "",
            profile_id: ""

        };
    }

    componentWillMount() {
        this.props.listRoles();
        this.props.listExtensions();
        this.props.listGroups();


    }
    componentDidUpdate(prevProps, prevState) {

        const { roles, configurations, groups } = this.props;

        if (roles !== null) {
            roleOption.splice(0, roleOption.length);
            for (var i = 0; i < roles.length; i++) {
                roleOption.push({ value: roles[i].role_id, label: roles[i].name });
            }
        }

        if (configurations !== null) {
            extensionOption.splice(0, extensionOption.length);
            for (var j = 0; j < configurations.length; j++) {
                extensionOption.push({ value: configurations[j].id, label: configurations[j].extension });
            }
        }

        if (groups !== null) {
            groupOption.splice(0, groupOption.length);
            for (var k = 0; k < this.props.groups.length; k++) {
                groupOption.push({ value: groups[k].group_id, label: groups[k].group_name });
            }
        }

        if (!groups || !roles || !configurations) {
            return;
        }

        if (this.props.userAccData.login_id && this.state.login_id !== this.props.userAccData.login_id) {
            const { userAccData } = this.props;
            var extensionData = _.find(configurations, { extension: userAccData.extension });

            this.setState({
                login_id: userAccData.login_id,
                login_username: userAccData.login_username,
                first_name: userAccData.first_name,
                last_name: userAccData.last_name,
                email: userAccData.email,
                phone_office: userAccData.phone_office,
                login_role_id: userAccData.login_role_id,
                extension_id: extensionData.id,
                work_group_id: userAccData.group_id || userAccData.workGroup_id,
                selected_role: { value: userAccData.login_role_id, label: userAccData.login_role_name },
                selected_extension: { value: extensionData.id, label: extensionData.extension },
                selected_group: { value: userAccData.group_id, label: userAccData.work_group },
                profile_id: userAccData.profileID
            });
            console.log(this.state);
        }

    }

    firstNameOnChange = (event) => {
        this.setState({ first_name: event.target.value });
    }
    lastNameOnChange = (event) => {
        this.setState({ last_name: event.target.value });
    }
    userNameOnChange = (event) => {
        this.setState({ login_username: event.target.value });
    }
    passwordOnChange = (event) => {
        this.setState({ login_password: event.target.value });
    }
    phoneOnChange = (event) => {
        this.setState({ phone_office: event.target.value });
    }
    emailOnChange = (event) => {
        this.setState({ email: event.target.value });
    }

    roleOnChange = (event) => {

        this.setState({ selected_role: event });
        this.setState({ login_role_id: event.value });
    }

    extensionOnChange = (event) => {
        this.setState({ selected_extension: event });
        this.setState({ extension_id: event.value });
    }

    groupOnChange = (event) => {
        this.setState({ selected_group: event });
        this.setState({ work_group_id: event.value });
    }
    confPasswordOnChange = (event) => {
        this.setState({ conf_password: event.target.value });
    }
    clearForm() {
        this.setState({
            login_username: "",
            login_password: "",
            login_role_id: "",
            first_name: "",
            last_name: "",
            email: "",
            phone_office: "",
            extension_id: "",
            work_group_id: "",
            conf_password: "",
            error_name: "",
            error_username: "",
            error_email: "",
            error_password: "",
            error_conf_password: "",
            error_role: "",
            selected_role: "",
            selected_extension: "",
            selected_group: "",
            login_id: "",
            profile_id: ""
        });

        this.props.onClose();
    }
    saveData() {
        var editData = {
            login_id: this.state.login_id,
            login_username: this.state.login_username,
            login_role_id: this.state.login_role_id,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            phone_office: this.state.phone_office,
            extension_id: this.state.extension_id,
            work_group_id: this.state.work_group_id,
            profile_id: this.state.profile_id
        }

        var isValid = false;
        if (this.state.first_name !== '') {
            this.setState({ error_name: '' });
            if (this.state.login_username !== '') {
                this.setState({ error_username: '' });
                if (this.state.login_id === "") {
                    if (this.state.login_password !== '') {
                        this.setState({ error_password: '' });
                        if (this.state.conf_password === this.state.login_password) {
                            this.setState({ error_conf_password: '' });
                            if (this.state.email !== '') {
                                this.setState({ error_email: '' });
                                if (this.state.login_role_id !== '') {
                                    isValid = true;
                                    this.setState({ error_role: '' });
                                } else {
                                    this.setState({ error_role: 'Role Required' });
                                }

                            } else {
                                this.setState({ error_email: 'Email Required' });
                            }
                        } else {
                            this.setState({ error_conf_password: 'Password not matched' });
                        }
                    } else {
                        this.setState({ error_password: 'Password Required' });
                    }
                } else {
                    if (this.state.login_password !== '') {
                        this.setState({ error_password: '' });
                        if (this.state.conf_password === this.state.login_password) {
                            editData.login_password = this.state.login_password;
                            this.setState({ error_conf_password: '' });
                            if (this.state.email !== '') {
                                this.setState({ error_email: '' });
                                if (this.state.login_role_id !== '') {
                                    isValid = true;
                                    this.setState({ error_role: '' });
                                } else {
                                    this.setState({ error_role: 'Role Required' });
                                }

                            } else {
                                this.setState({ error_email: 'Email Required' });
                            }

                        } else {
                            this.setState({ error_conf_password: 'Password not matched' });

                        }
                    } else {
                        if (this.state.email !== '') {
                            this.setState({ error_email: '' });
                            if (this.state.login_role_id !== '') {
                                isValid = true;
                                this.setState({ error_role: '' });
                            } else {
                                this.setState({ error_role: 'Role Required' });
                            }

                        } else {
                            this.setState({ error_email: 'Email Required' });
                        }
                    }
                }
            } else {
                this.setState({ error_username: 'Username Required' });
            }
        } else {
            this.setState({ error_name: 'First Name Required' });
        }

        if (isValid) {
            if (this.state.login_id === "") {
                this.props.createUser(this.state);
                this.clearForm();
            } else {
                this.props.updateUser(editData);
                this.clearForm();
            }

        }
    }

    render() {

        //const { data } = this.props;

        return (
            <CardBody>
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label htmlFor="first_name">First Name</Label>
                            <Input type="text" id="first_name" placeholder="Enter the first name" value={this.state.first_name} onChange={this.firstNameOnChange} required />
                            {this.state.error_name ? <FormText color="danger">{this.state.error_name}</FormText> : ''}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label htmlFor="last_name">Last Name</Label>
                            <Input type="text" id="last_name" placeholder="Enter the last name" value={this.state.last_name} onChange={this.lastNameOnChange} required />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label htmlFor="email">Email</Label>
                            <Input type="text" id="email" placeholder="Enter the email" value={this.state.email} onChange={this.emailOnChange} required />
                            {this.state.error_email ? <FormText color="danger">{this.state.error_email}</FormText> : ''}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label htmlFor="phone_office">Phone Number</Label>
                            <Input type="text" id="phone_office" placeholder="Enter the phone number" value={this.state.phone_office} onChange={this.phoneOnChange} required />
                        </FormGroup>
                    </Col>
                </Row>
                {!this.props.own && <div>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="login_username">Username</Label>
                                <Input type="text" id="login_username" placeholder="Enter the username" value={this.state.login_username} onChange={this.userNameOnChange} required />
                                {this.state.error_username ? <FormText color="danger">{this.state.error_username}</FormText> : ''}

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="role">Role</Label>
                                <Select
                                    name="form-field-name2"
                                    value={this.state.selected_role}
                                    options={roleOption}
                                    onChange={this.roleOnChange}
                                />
                                {this.state.error_role ? <FormText color="danger">{this.state.error_role}</FormText> : ''}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="name">Extension</Label>
                                <Select
                                    name="form-field-name2"
                                    value={this.state.selected_extension}
                                    options={extensionOption}
                                    onChange={this.extensionOnChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="name">Group</Label>
                                <Select
                                    name="form-field-name2"
                                    value={this.state.selected_group}
                                    options={groupOption}
                                    onChange={this.groupOnChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>}
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label htmlFor="login_password">Password</Label>
                            <Input type="password" id="login_password" autoComplete="new-password" placeholder="Enter the password" value={this.state.login_password} onChange={this.passwordOnChange} required />
                            {this.state.error_password ? <FormText color="danger">{this.state.error_password}</FormText> : ''}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <Label htmlFor="conf_password">Confirm Password</Label>
                            <Input type="password" id="conf_password" autoComplete="new-password" placeholder="Confirm the password" value={this.state.conf_password} onChange={this.confPasswordOnChange} required />
                            {this.state.error_conf_password ? <FormText color="danger">{this.state.error_conf_password}</FormText> : ''}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <Button onClick={() => this.saveData()} color="primary">Save</Button>{' '}
                        <Button onClick={() => this.clearForm()} color="danger">Cancel</Button>
                    </Col>
                </Row>
            </CardBody>
        );
    }
}

function mapStateToProps({ roles, permissions, groups, configurations }) {
    return {
        roles, permissions, groups, configurations
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        listRoles,
        listGroups,
        listExtensions,
        createUser,
        updateUser

    }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(CreateUser));
