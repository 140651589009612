import React from 'react';
import Loadable from 'react-loadable';

import Home from '../containers/Home';
import Loader from '../components/Loader';

function Loading() {
  return <Loader />;
}

const Dashboard = Loadable({
  loader: () => import('../containers/views/Dashboard/index'),
  loading: Loading,
});

const Campaigns = Loadable({
  loader: () => import('../containers/views/Campaigns/index'),
  loading: Loading,
});

const NewCampaign = Loadable({
  loader: () => import('../containers/views/Campaigns/create'),
  loading: Loading,
});

const Facilities = Loadable({
  loader: () => import('../containers/views/Facilities/index'),
  loading: Loading,
});

const DialFacility = Loadable({
  loader: () => import('../containers/views/Facilities/view'),
  loading: Loading,
});

const FollowUps = Loadable({
  loader: () => import('../containers/views/FollowUps/index'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('../containers/views/Users/index'),
  loading: Loading,
});

const Roles = Loadable({
  loader: () => import('../containers/views/Roles/index'),
  loading: Loading,
});

const Reports = Loadable({
  loader: () => import('../containers/views/Reports/KPIReports'),
  loading: Loading,
});

const Extensions = Loadable({
  loader: () => import('../containers/views/Configurations/Extentions/index'),
  loading: Loading,
});

const AgentConfigs = Loadable({
  loader: () => import('../containers/views/Configurations/AgentConfigs/index'),
  loading: Loading,
});

const GroupConfigs = Loadable({
  loader: () => import('../containers/views/Configurations/GroupConfigs/index'),
  loading: Loading,
});

const Groups = Loadable({
  loader: () => import('../containers/views/Groups/index'),
  loading: Loading,
});

const Templates = Loadable({
  loader: () => import('../containers/views/Templates/Campaign/index'),
  loading: Loading,
});

const EmailTemplates = Loadable({
  loader: () => import('../modules/email-templates/views/index'),
  loading: Loading,
});

const SMSTemplates = Loadable({
  loader: () => import('../modules/sms-templates/views/index'),
  loading: Loading,
});

const CBSTemplates = Loadable({
  loader: () => import('../modules/cbs-note-templates/views/index'),
  loading: Loading,
});

const FollowUpTemplates = Loadable({
  loader: () => import('../modules/followup-note-templates/views/index'),
  loading: Loading,
});

const DispositionTemplates = Loadable({
  loader: () => import('../modules/disposition-note-templates/views/index'),
  loading: Loading,
});

const DialerReports = Loadable({
  loader: () => import('../containers/views/Reports/DialerReports'),
  loading: Loading,
});

const KPIAnalyzer = Loadable({
  loader: () => import('../containers/views/Reports/KPIReports'),
  loading: Loading,
});

const AgentPerformance = Loadable({
  loader: () => import('../containers/views/Reports/AgentPerformance'),
  loading: Loading,
});

const AgentEtiquettes = Loadable({
  loader: () => import('../containers/views/Reports/AgentEtiquettes'),
  loading: Loading,
});

const AgentMonitor = Loadable({
  loader: () => import('../containers/views/Reports/ActivityMonitor'),
  loading: Loading,
});

const ActivityReport = Loadable({
  loader: () => import('../containers/views/Reports/ActivityReport'),
  loading: Loading,
});

const AgentActivity = Loadable({
  loader: () => import('../containers/views/Reports/AgentActivity'),
  loading: Loading,
});

const QueueStatistics = Loadable({
  loader: () => import('../containers/views/Reports/QueueStatistics'),
  loading: Loading,
});

const CallCounts = Loadable({
  loader: () => import('../containers/views/Reports/CallCounts'),
  loading: Loading,
});

const Collections = Loadable({
  loader: () => import('../containers/views/Reports/Collections'),
  loading: Loading,
});

const CallLogs = Loadable({
  loader: () => import('../containers/views/Reports/CallLogs'),
  loading: Loading,
});

const FieldLayout = Loadable({
  loader: () => import('../modules/field-layout/views/index'),
  loading: Loading,
});

const UploadLeads = Loadable({
  loader: () => import('../modules/leads/views/upload'),
  loading: Loading,
});

const routes = [
  { path: '/', name: '', component: Home, exact: true },
  { path: '/', name: '', component: Home, exact: true, isAuth: true },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, exact: true, isAuth: true },
  { path: '/campaigns', name: 'Campaigns', component: Campaigns, exact: true, isAuth: true },
  { path: '/campaigns/new', name: 'New Campaign', component: NewCampaign, isAuth: true },
  { path: '/leads', name: 'Leads', component: Facilities, exact: true, isAuth: true },
  { path: '/leads/dial', name: 'Lead Details', component: DialFacility, isAuth: true },
  { path: '/followups', name: 'Follow Ups', component: FollowUps, isAuth: true },
  { path: '/extensions', name: 'Extensions', component: Extensions, isAuth: true },
  { path: '/users', name: 'Users', component: Users, isAuth: true },
  { path: '/reports', name: 'Reports', component: Reports, isAuth: true },
  { path: '/roles', name: 'Roles', component: Roles, isAuth: true },
  { path: '/groups', name: 'Groups', component: Groups, isAuth: true },
  { path: '/group-configs', name: 'Group', component: GroupConfigs },
  { path: '/agent-configs', name: 'Agent', component: AgentConfigs },
  { path: '/templates', name: 'Templates', component: Templates },
  { path: '/email-templates', name: 'Email Templates', component: EmailTemplates },
  { path: '/sms-templates', name: 'SMS Templates', component: SMSTemplates },
  { path: '/cbs-templates', name: 'CBS Note Templates', component: CBSTemplates },
  { path: '/followup-templates', name: 'Follow Up Note Templates', component: FollowUpTemplates },
  { path: '/disposition-templates', name: 'Disposition Note Templates', component: DispositionTemplates },
  { path: '/dialer-reports', name: 'Dialer Reports', component: DialerReports },
  { path: '/kpi-analyzer', name: 'KPI Analyzer', component: KPIAnalyzer },
  { path: '/agent-performance', name: 'Agent Performance', component: AgentPerformance },
  { path: '/queue-statistics', name: 'Queue Statistics', component: QueueStatistics },
  { path: '/agent-activity', name: 'Agent Activity', component: AgentActivity },
  { path: '/agent-etiquettes', name: 'Agent Etiquettes', component: AgentEtiquettes },
  { path: '/agent-monitor', name: 'Agent Activity', component: AgentMonitor },
  { path: '/activity-report', name: 'Agent Activity', component: ActivityReport },
  { path: '/agent-activity', name: 'Agent Activity', component: AgentActivity },
  { path: '/call-counts', name: 'Call Counts', component: CallCounts },
  { path: '/collections', name: 'Recovery Collections', component: Collections },
  { path: '/call-logs', name: 'Call Logs', component: CallLogs },
  { path: '/field-layout', name: 'Field Layout', component: FieldLayout },
  { path: '/leads/upload', name: 'Upload Leads', component: UploadLeads },
];

export default routes;
