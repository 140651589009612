import { AGENT_COLLECTIONS_LOADED } from '../actions/config';
import _ from "lodash";

export default function (state = null, action) {
    switch (action.type) {
        case AGENT_COLLECTIONS_LOADED:
            const result = [];

            for (let i = 0; i < action.payload.Monthly_data.length; i++) {
                const { extension, agentName } = action.payload.Monthly_data[i];
                const data = { extension, agentName };
                data["monthly_count"] = _.find(action.payload.Monthly_data, { extension, agentName }).count;
                data["monthly_collection"] = _.find(action.payload.Monthly_data, { extension, agentName }).collection;
                data["weekly_count"] = _.find(action.payload.weekly_data, { extension, agentName }).count;
                data["weekly_collection"] = _.find(action.payload.weekly_data, { extension, agentName }).collection;
                data["daily_count"] = _.find(action.payload.daily_data, { extension, agentName }).count;
                data["daily_collection"] = _.find(action.payload.daily_data, { extension, agentName }).collection;
                data["daily_target"] = _.find(action.payload.daily_archivements, { extension, agentName }).callection_target;
                data["daily_percentage"] = _.find(action.payload.daily_archivements, { extension, agentName }).percentage;
                result.push(data);
            }

            return result;
        default:
            return state;
    }
}
