import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import { API_URL, KANTAR_API_URL, MI_API_URL } from '../config/globals';

import { CAPITAL_LEDGER_LOADED, NEXT_CUSTOMER_LOADED, NOTES_LOADED, INSURANCE_LOADED, FACILITY_CUSTOMER_LOADED, OWN_FACILITY_COUNT_LOADED, GUARANTEED_FACILITY_COUNT_LOADED, VEHICLE_LOADED, GAURANTORS_LOADED, PD_CHEQUES_LOADED, PAYMENT_HISTORY_LOADED, FACILITY_LOADED, ARREARS_BREAKDOWN_LOADED, CUSTOMER_LOADED, FACILITIES_LOADED, FOLLOWUPS_LOADED, FOLLOWUP_METADATA_LOADED, PAYMENT_SCHEDULE_LOADED, searchTypes } from './config';
import { getNow } from '../utils/common';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Content-Type'] = "application/json";

export const searchCustomer = (search, searchBy, type) => {
    if (!search) {
        return;
    }

    let customer_id = search;

    return async (dispatch, getState) => {
        if (searchBy === searchTypes.PHONE_NUMBER) {
            axios({
                method: 'get',
                url: API_URL + '/AccessManager/getLeadbyPhoneNumber',
                params: {
                    access_token: getState().user.access_token,
                    phonenumber: search
                }
            }).then((results) => {
                dispatch({
                    type: CUSTOMER_LOADED,
                    payload: results.data
                });
            }).catch(function (error) {
                toastr.error('Error', error.message);
            });
        } else {
            axios({
                method: 'get',
                url: API_URL + '/AccessManager/getLeadbyCustomerID',
                params: {
                    access_token: getState().user.access_token,
                    customer_id: search
                }
            }).then((results) => {
                dispatch({
                    type: CUSTOMER_LOADED,
                    payload: results.data
                });
            }).catch(function (error) {
                toastr.error('Error', error.message);
            });
        }
    }
}

export const getNextFacility = (next) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/GetNextCustomertoDial',
            params: {
                access_token: getState().user.access_token,
                user_id: getState().user.login_id
            }
        }).then(function (results) {
            if (results.data.customer_id) {
                dispatch({
                    type: NEXT_CUSTOMER_LOADED,
                    payload: results.data
                });
                next(results.data.customer_id);
            } else {
                dispatch({
                    type: CUSTOMER_LOADED,
                    payload: null
                });
                toastr.warning('No Data Found', "No facility detailes returned for calling");
            }
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadFacilities = (campaign_id = "") => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: KANTAR_API_URL + '/GetLeadsList.htm',
            params: {
                access_token: getState().user.access_token,
                campaign_id: campaign_id
            }
        }).then(function (results) {
            dispatch({
                type: FACILITIES_LOADED,
                payload: results.data
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadFacilityDetails = (contract_number) => {
    console.log(contract_number);
    return (dispatch, getState) => {
        dispatch(loadNotes(contract_number));

        axios({
            method: 'get',
            url: MI_API_URL + '/getPaymentScheduleByContractNumber',
            params: {
                access_token: getState().user.access_token,
                contract_number
            },
        }).then(function (results) {
            dispatch({
                type: PAYMENT_SCHEDULE_LOADED,
                contract_number: contract_number,
                payload: results.data
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });

        axios({
            method: 'get',
            url: MI_API_URL + '/getArrearsBreakdownByContractNumber',
            params: {
                access_token: getState().user.access_token,
                contract_number
            },
        }).then(function (results) {
            dispatch({
                type: ARREARS_BREAKDOWN_LOADED,
                contract_number: contract_number,
                payload: results.data
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });

        axios({
            method: 'get',
            url: MI_API_URL + '/getArrearsBreakdownByContractNumberD101',
            params: {
                access_token: getState().user.access_token,
                contract_number
            },
        }).then(function (results) {
            dispatch({
                type: CAPITAL_LEDGER_LOADED,
                contract_number: contract_number,
                payload: results.data
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });

        axios({
            method: 'get',
            url: MI_API_URL + '/getPDChequesByContractNumber',
            params: {
                access_token: getState().user.access_token,
                contract_number
            }
        }).then(function (results) {
            dispatch({
                type: PD_CHEQUES_LOADED,
                contract_number: contract_number,
                payload: results.data.detail
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });

        axios({
            method: 'get',
            url: MI_API_URL + '/getChequePaymentHistoryByContractNumber',
            params: {
                access_token: getState().user.access_token,
                contract_number
            }
        }).then(function (results) {
            dispatch({
                type: PAYMENT_HISTORY_LOADED,
                contract_number: contract_number,
                payload: results.data
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });

        axios({
            method: 'get',
            url: MI_API_URL + '/getGuarantorsByContractNumber',
            params: {
                access_token: getState().user.access_token,
                contract_number
            }
        }).then(function (results) {
            dispatch({
                type: GAURANTORS_LOADED,
                contract_number: contract_number,
                payload: results.data.Detail
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });

        axios({
            method: 'get',
            url: MI_API_URL + '/getCustomerDetailsbyContractNumber',
            params: {
                access_token: getState().user.access_token,
                contract_number
            }
        }).then(function (results) {
            dispatch({
                type: FACILITY_CUSTOMER_LOADED,
                contract_number: contract_number,
                payload: results.data
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });

        axios({
            method: 'get',
            url: MI_API_URL + '/getInsuranceDetailsByContractNumber',
            params: {
                access_token: getState().user.access_token,
                contract_number
            }
        }).then(function (results) {
            dispatch({
                type: INSURANCE_LOADED,
                contract_number: contract_number,
                payload: results.data.Policy
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });

        axios({
            method: 'get',
            url: MI_API_URL + '/getVehicleDetailsByContractNumber',
            params: {
                access_token: getState().user.access_token,
                contract_number
            }
        }).then(function (results) {
            dispatch({
                type: VEHICLE_LOADED,
                contract_number: contract_number,
                payload: results.data.Detail[0]
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const followUp = (data, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/DialerCore/setFollowupsReminders',
            params: {
                access_token: getState().user.access_token
            },
            data
        }).then(function (results) {
            toastr.success('Saved', "Follow up note saved successfully.");
            dispatch(loadFollowUps());
            next();
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const call = (data, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/Dial',
            params: {
                access_token: getState().user.access_token,
                customer_id: data.customer_id,
                number: data.number,
                extension: getState().user.user_details.extension
            }
        }).then(function (results) {
            next();
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadFollowUps = (data) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/GetFollowupCustomerList',
            params: {
                access_token: getState().user.access_token,
                user_id: getState().user.login_id
            },
            data
        }).then(function (results) {
            dispatch({
                type: FOLLOWUPS_LOADED,
                payload: results.data.followupList
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadFollowUpMetaData = (data) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: MI_API_URL + '/getNoteCodeList',
            params: {
                access_token: getState().user.access_token
            },
            data
        }).then(function (results) {
            dispatch({
                type: FOLLOWUP_METADATA_LOADED,
                payload: results.data.notesMasterDataVM
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const deleteFollowUps = (customer_id) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/clearFollowupReminder',
            params: {
                access_token: getState().user.access_token,
                customer_id
            }
        }).then(function (response) {
            if (response.data) {
                dispatch(loadFollowUps());
            } else {
                toastr.error('Error', 'Failed to delete the follow up detail');
            }
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}


export const deleteFacility = (lead_id, campaign_id = "") => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: KANTAR_API_URL + '/deleteLeadByID',
            params: {
                access_token: getState().user.access_token,
                lead_id
            }
        }).then(function (response) {
            if (response.data) {
                dispatch(loadFacilities(campaign_id));
            } else {
                toastr.error('Error', 'Failed to delete the facility detail');
            }
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const addNote = (data, next) => {
    return (dispatch, getState) => {

        data.strNote = `${data.strNote} - Added by ${getState().user.user_details.first_name.trim()} ${getState().user.user_details.last_name.trim()} on ${getNow()}`;

        axios({
            method: 'post',
            url: MI_API_URL + '/AddNoteToContract',
            params: {
                access_token: getState().user.access_token
            },
            data
        }).then(function () {
            toastr.success('Saved', "Note saved successfully.");
            dispatch(loadNotes(data.contract_number));
            next();
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadNotes = (contract_number) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: MI_API_URL + '/getNotesByContractNumber',
            params: {
                access_token: getState().user.access_token,
                contract_number
            },
        }).then(function (results) {
            dispatch({
                type: NOTES_LOADED,
                contract_number: contract_number,
                payload: results.data.Detail
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const searchByVehicle = (VehicleNo, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: MI_API_URL + '/getNICListByVehicleNumber',
            params: {
                access_token: getState().user.access_token,
                VehicleNo
            },
        }).then(function (results) {
            next(results.data.Detail);
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const changeFollowupOwnership = (data, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/DialerCore/changeFollowupOwnership',
            params: {
                access_token: getState().user.access_token
            },
            data: {
                ...data,
                owner_user_id: getState().user.login_id
            }
        }).then(function (results) {
            toastr.success('Success', "Follow-Ups are transfered successfully");
            next();
            dispatch(loadFollowUps());
        }).catch(function (error) {
            next(error);
            toastr.error('Error', error.message);
        });
    }
}