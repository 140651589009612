import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';

import LoginForm from './forms/LoginForm';
import { login } from '../actions/index';
import logo from '../theme/logo-dark.png';
import text from '../theme/texts.json';

class Login extends Component {

  render() {
    const { login } = this.props;
    return (
      <div className="bg-primary text-dark app flex-row align-items-center">
        <Container>
          <Row className="text-dark justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="bg-light p-4">
                  <CardBody>
                    <LoginForm onSubmit={login} />
                  </CardBody>
                </Card>
                <Card className=" py-3 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <img src={logo} alt={"SimSyn"} className="my-4" style={{ width: 150 }} />
                      <h4>{text.title}</h4>
                      <p style={{ fontSize: "0.7rem", textAlign: "justify" }}>{text.description}</p>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    login
  }, dispatch);
}


export default (connect(null, mapDispatchToProps)(Login));