import { USERS_LOADED } from '../actions/config';

export default function (state = null, action) {
    switch (action.type) {
        case USERS_LOADED:
            return action.payload;
        // case USER_DELETED:
        //     return action.payload;
        default:
            return state;
    }
}
