import { AGENT_CALL_COUNTS_LOADED } from '../actions/config';
import _ from "lodash";

export default function (state = null, action) {
    switch (action.type) {
        case AGENT_CALL_COUNTS_LOADED:
            const result = [];

            for (let i = 0; i < action.payload.Monthly_data.length; i++) {
                const { extension, agentName } = action.payload.Monthly_data[i];
                const data = { extension, agentName };
                data["monthly_answered"] = _.find(action.payload.Monthly_data, { extension, agentName }).outbound_call_count_answered;
                data["monthly_unanswered"] = _.find(action.payload.Monthly_data, { extension, agentName }).outbound_call_count_unanswered;
                data["weekly_answered"] = _.find(action.payload.weekly_data, { extension, agentName }).outbound_call_count_answered;
                data["weekly_unanswered"] = _.find(action.payload.weekly_data, { extension, agentName }).outbound_call_count_unanswered;
                data["daily_answered"] = _.find(action.payload.daily_data, { extension, agentName }).outbound_call_count_answered;
                data["daily_unanswered"] = _.find(action.payload.daily_data, { extension, agentName }).outbound_call_count_unanswered;
                data["daily_target"] = _.find(action.payload.daily_archivements, { extension, agentName }).calling_target;
                data["daily_percentage"] = _.find(action.payload.daily_archivements, { extension, agentName }).percentage;
                result.push(data);
            }

            return result;
        default:
            return state;
    }
}
