import { toastr } from "react-redux-toastr";
import axios from "axios";
import { API_URL } from "../config/globals";
import { ROLES_LOADED } from "./config";

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";

export const listRoles = () => {
  return (dispatch, getState) => {
      axios({
        method: "get",
        url: API_URL + "/AccessManager/RolesList",
        params: {
          access_token: getState().user.access_token,
        },
      })
        .then(function (response) {
          dispatch({ type: ROLES_LOADED, payload: response.data.roles });
        })
        .catch(function (error) {
          toastr.error("Error", error.message);
        });
  };
};

export const deleteRole = (props) => {
  return (dispatch, getState) => {
    axios({
      method: "get",
      url: API_URL + "/AccessManager/RoleDelete",
      params: {
        access_token: getState().user.access_token,
        role_id: props,
      },
    })
      .then(function (response) {
        if (response.data) {
          if (response.data.status !== "error") {
            toastr.success("Success", response.data.message);
          } else {
            toastr.error("Error", response.data.message);
          }
          dispatch(listRoles());
        } else {
          toastr.error("Error", "Failed to delete Role");
        }
      })
      .catch(function (error) {
        toastr.error("Error", error.message);
      });
  };
};

export const createRole = (props) => {
  return (dispatch, getState) => {
    const rules = props.rules.filter(Boolean);
    axios({
      method: "post",
      url: API_URL + "/AccessManager/RoleAdd",
      params: {
        access_token: getState().user.access_token,
      },
      data: {
        name: props.role_name,
        description: props.role_description,
        rules: rules,
      },
    })
      .then(function (response) {
        if (response.data) {
          toastr.success("Success", response.data.message);
          dispatch(listRoles());
        } else {
          toastr.error("Error", "Failed to create the role");
        }
      })
      .catch(function (error) {
        toastr.error("Error", error.message);
      });
  };
};

export const updateRole = (props) => {
  return (dispatch, getState) => {
    axios({
      method: "post",
      url: API_URL + "/AccessManager/RoleModify",
      params: {
        access_token: getState().user.access_token,
      },
      data: {
        role_id: props.role_id,
        name: props.role_name,
        description: props.role_description,
        rules: props.rules,
      },
    })
      .then(function (response) {
        if (response.data) {
          toastr.success("Success", response.data.message);
          dispatch(listRoles());
        } else {
          toastr.error("Error", "Failed to update the role");
        }
      })
      .catch(function (error) {
        toastr.error("Error", error.message);
      });
  };
};
